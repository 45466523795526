import React from 'react'
import { LayoutWrapper } from '../components/layout/layoutWrapper'
import BasicBookmarks from '../components/bookmarks/basicBookmarks'
import { useNavigationItems } from '../hooks/useNavigationItems'
import { AppLocale, GenerateLocales } from '@hrk/types'
import { SiteMetadata, useSiteMetadata } from '../hooks/useSiteConfig'

const Bookmarks: React.FC = () => {
  const currentSlug = '/en/code'
  const { collectBreadCrumbs } = useNavigationItems()
  const currentPagePath = collectBreadCrumbs({ slug: currentSlug, title: 'Code', type: 'EditorialPages' })
  return (
    <LayoutWrapper
      currentTitle="Code"
      currentLocale={GenerateLocales.en}
      currentSlug={currentSlug}
      otherLocales={[{ locale: GenerateLocales.de, slug: '/code/' }]}
      currentPath={currentPagePath}
    >
      <BasicBookmarks />
    </LayoutWrapper>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return <link rel="canonical" href={`${site.metadata.siteUrl}/en/code/`} />
}

export default Bookmarks
